// Styles for input control
input,
textarea {
	border: 1px solid var(--border-color);
	min-height: var(--control-height);
	border-radius: 4px;
	width: 100%;
	margin: 0;
	padding: 0 var(--space-s);
	font-family: var(--font-family-base);

	&::placeholder {
		font-size: 1rem;
		color: var(--placeholder-color);
	}
}

textarea {
	min-height: 4rem;
	padding: var(--space-s);
	font-family: var(--font-family-base);
	resize: vertical;
}

// Styles for general select or multi select control
.mat-mdc-select {
	border: 1px solid var(--border-color);
	border-radius: 4px;
	padding: 0 10px;
	width: 100%;
	height: var(--control-height);
	background-color: var(--base-background-color);

	.mat-mdc-select-placeholder {
		font-size: 1rem;
		color: var(--placeholder-color);
	}
}

.mat-mdc-select-disabled {
	background-color: var(--disabled-input-background);

	.mat-mdc-select-value {
		color: var(--disabled-input-color);
	}
}

.mat-mdc-select-trigger {
	height: 100%;
}

.mat-mdc-select-value {
	vertical-align: middle;
}

// Styles for datepicker control
.datepicker-toggle {
	position: absolute;
	right: 0;
}

.custom-stepper {
	.mat-horizontal-stepper-header-container {
		margin-bottom: var(--space-l);
	}
}

.control-container {
	.mat-mdc-chip-set {
		align-items: flex-start;
		width: 100%;
		margin: -3px;

		.mat-icon {
			display: flex;
			align-items: center;
			top: 0;
			height: 100%;
		}
	}
}

.custom-calendar {
	.cal-month-view .cal-days {
		border: none;
		border-top: 1px solid var(--border-color);
	}

	.cal-month-view .cal-day-cell.cal-today {
		background-color: var(--base-background-color-dark);
	}
}

// To move mat-select dropdown under control
.mat-mdc-select-panel.mdc-menu-surface.custom-select-dropdown {
	position: relative;
	margin-top: 48px;
}

.ql-toolbar {
	border-radius: 4px 4px 0 0;
}

.ql-container {
	min-height: var(--editor-min-height, auto);
	border-radius: 0 0 4px 4px;

	p {
		font-family: var(--font-family-base);
	}
}

.mat-mdc-chip-grid .mat-mdc-standard-chip {
	border-radius: 6px;
}

quill-view.small-quill {
	--number-of-visible-lines: 2;
	.ql-editor {
		padding: 0;
		word-wrap: break-word;
		white-space: pre-line;
		display: -webkit-box;
		-webkit-line-clamp: var(--number-of-visible-lines);
		-webkit-box-orient: vertical;
		overflow: hidden;

		h1,
		h2,
		.ql-size-huge {
			font-size: 1.5rem;
		}
	}
}

quill-view .ql-editor {
	padding: 0;
	font-size: var(--font-size);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused
	.mat-calendar-body-active
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused
	.mat-calendar-body-active
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
	color: var(--primary-contrast-color);
}

.custom-select-list {
	.mat-mdc-checkbox {
		width: 100%;

		.mdc-form-field {
			width: 100%;
			user-select: auto;
		}

		.mdc-form-field label[for^="mat-mdc-checkbox"] {
			width: 100%;
		}
	}

	.mat-mdc-radio-button {
		width: 100%;

		.mdc-form-field {
			width: 100%;
			align-items: flex-start;
			user-select: auto;
		}

		.mdc-form-field label[for^="mat-radio"] {
			width: 100%;
		}
	}
}

.mat-mdc-checkbox.mat-accent {
	--mdc-checkbox-selected-checkmark-color: var(--primary-light-contrast-color);
}

.mat-mdc-slide-toggle {
	/* Track in the selected state. */
	--mdc-switch-selected-focus-track-color: var(--primary-color);
	--mdc-switch-selected-hover-track-color: var(--primary-color);
	--mdc-switch-selected-pressed-track-color: var(--primary-color);
	--mdc-switch-selected-track-color: var(--primary-color);

	/* Selected icon. */
	--mdc-switch-selected-icon-color: var(--primary-light-contrast-color);

	/* Surface in the selected state. */
	--mdc-switch-handle-surface-color: var(--primary-color);
}
