@use "./angular-material-custom-theme";
@use "./elements/style";

@import "../../../node_modules/angular-calendar/css/angular-calendar.css";
@import "./variables.css";
@import "./utils.css";
@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";

*,
*::after,
*::before {
	box-sizing: border-box;
}

html {
	font-size: var(--font-size);
	font-family: var(--font-family);
}

body {
	color: var(--base-color);
}

html,
body {
	margin: 0;
	height: 100%;
}

h1 {
	font-size: var(--h1-font-size);
}

h2 {
	font-size: var(--h2-font-size);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

a {
	text-decoration: none;
}

fieldset {
	border: none;
}

mat-progress-bar.mat-mdc-progress-bar {
	.mdc-linear-progress__buffer-bar {
		background-color: var(--primary-contrast-color);
	}
}

.full-width {
	width: 100%;
}

.full-height {
	height: 100%;
}

.link {
	color: var(--faint-color);
	text-decoration: none;
	transition: 0.2s;
}

.link:hover {
	color: var(--faint-dark-color);
	transition: 0.2s;
}

.hidden {
	display: none;
}

.form-error {
	font-weight: bold;
	margin: 5px 0;
	text-align: center;
	color: var(--error-font-color);
}

.mat-mdc-dialog-container {
	padding: 0;
}

.mat-mdc-tab-labels,
.mat-mdc-tab-links {
	.mat-tab-label,
	.mat-mdc-tab-link {
		opacity: 0.8;
		color: var(--faint-dark-color);
	}

	.mat-tab-label-active {
		opacity: 1;
		color: var(--primary-color);
	}
}

.mat-mdc-tab-group,
.mat-mdc-tab-body-wrapper,
.mat-mdc-tab-body,
.mat-mdc-tab-body-content {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100px;
	flex: 1;
}

.mdc-evolution-chip-set .mdc-evolution-chip {
	font-family: var(--font-family);
	font-weight: 500;
}

.mdc-snackbar {
	--mdc-snackbar-supporting-text-font: var(--font-family);
}

.mat-mdc-tab-nav-bar {
	border-bottom: var(--border);
}

.mat-mdc-chip {
	--mdc-chip-label-text-font: var(--font-family);
	--mdc-chip-label-text-weight: 500;
}

.mat-mdc-chip-set.mat-mdc-chip-grid .mdc-evolution-chip-set__chips {
	margin-left: 0;
}

.mdc-tab.mat-mdc-tab-link {
	min-width: 160px;
}

.mat-mdc-option {
	--mat-option-label-text-size: var(--select-option-font-size);
}
