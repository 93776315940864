.mat-mdc-snack-bar-container.custom-notification {
	font-weight: bold;

	&.primary-message > .mat-mdc-snackbar-surface {
		background-color: var(--primary-color);
	}

	&.success-message > .mat-mdc-snackbar-surface {
		background-color: var(--success-color);
	}

	&.warn-message > .mat-mdc-snackbar-surface {
		background-color: var(--warn-color);
	}
}
