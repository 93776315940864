/* Custom application color and font settings */
:root {
	/* Font */
	--font-size: 14px;
	--font-size-small: 12px;
	--font-family: "Roboto Slab", sans-serif;
	--font-family-base: "Roboto", sans-serif;
	--h1-font-size: 21px;
	--h2-font-size: 16px;
	--error-text-size: 14px;
	--select-option-font-size: 16px;

	/* Styles that don't represent the brand (stole this phrase from material.io) */

	/* Base color for a body text. */
	--base-color: #000000;

	/* Background color appears behind scrollable content. */
	--base-background-color: #ffffff;

	--disabled-background-color: #fafafa;

	/* Color for a text that's not important or it should not draw attention. */
	--faint-color: hsl(0, 0%, 70%);
	--faint-dark-color: hsl(0, 0%, 40%);
	--faint-light-color: hsl(0, 0%, 85%);

	/* Font color for surface background. */
	--surface-font-color: #000000;
	/* Affect surfaces of components, such as cards, sheets, and menus */
	--surface-background-color: #ffffff;

	/* Font color for error messages. */
	--error-font-color: #ee5757;
	/* In case error is a block with a message inside. */
	--error-background-color: #ffffff;

	/* Elements spacing. */
	--space-m: 15px;
	--space-xs: calc(var(--space-m) * 0.25);
	--space-s: calc(var(--space-m) * 0.5);
	--space-l: calc(var(--space-m) * 1.5);
	--space-xl: calc(var(--space-m) * 2.5);

	/* Size of default page padding */
	--page-space: var(--space-l);

	/* Brand colors */
	--primary-color: #2f4858;
	--primary-dark-color: #04212f;
	--primary-light-color: #5a7385;
	--primary-contrast-color: #49c5b6;
	--primary-dark-contrast-color: white;
	--primary-light-contrast-color: white;

	--accent-color: #1d6171;
	--accent-dark-color: #003746;
	--accent-light-color: #518f9f;
	--accent-contrast-color: white;
	--accent-dark-contrast-color: white;
	--accent-light-contrast-color: black;

	--warn-color: #da3e33;
	--warn-dark-color: #aa190f;
	--warn-light-color: #e4746c;
	--warn-contrast-color: white;
	--warn-dark-contrast-color: white;
	--warn-light-contrast-color: black;

	/* Util properties. Util properties must not define new values. */
	--radius: calc(var(--font-size) * 0.5);
	--border-color: var(--faint-light-color);

	/* Add your variables below, please */
	--placeholder-color: rgba(0, 0, 0, 0.42); /* Angular Material color. */
	--border: 1px solid var(--border-color);
	--header-height: 100px;
	--footer-height: 80px;
	--button-base-height: 42px;
	--control-height: 46px;
	--table-row-height: 48px;
	--base-background-color-dark: #fbfbfb;
	--success-color: #5bb762;
	--container-width: 1400px;
	--delete-color: #a21111;
	--card-shadow: 0 5px 10px 0 rgba(221, 221, 221, 0.5);
	--disabled-input-background: rgba(239, 239, 239, 0.3);
	--disabled-input-color: rgb(84, 84, 84);
	--accent-table-row: #ebf1f3;
	--disabled-table-cell: #f4f5f6;
	--highlight-table-cell: #f8f2cc;
}
