.page-row {
	padding: 0 var(--page-space);
}

.page-row .title {
	height: 70px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flex-container {
	display: flex;
}

.flex-container mat-form-field {
	margin-right: var(--space-l);
}

.flex-container mat-form-field:last-child {
	margin-right: 0;
}
