button {
	border: 0;
	cursor: pointer;
	border-radius: var(--radius);
}

button.primary {
	background-color: var(--primary-color);
	color: var(--primary-contrast-color);
}

button.primary:hover:not(:disabled):not(.loading) {
	background: var(--primary-dark-color);
}

button.primary:focus {
	box-shadow: 0 0 0 8px var(--primary-light-color);
	outline: none;
}

button.medium {
	min-width: 120px;
	height: 3rem;
}

button,
a {
	&.mat-mdc-button,
	&.mat-mdc-unelevated-button,
	&.mat-mdc-outlined-button,
	&.mat-mdc-raised-button {
		border-radius: 0;
		letter-spacing: 0;
	}
}

button.button-base,
a.button-base {
	width: 220px;
	height: var(--button-base-height);
}

.mat-mdc-unelevated-button.mat-mdc-button-base.custom-icon-button {
	height: auto;
}

.custom-icon-button {
	padding: 5px;
	background-color: transparent;

	.button-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: var(--accent-color);

		span {
			flex-grow: 0;
			line-height: 1.3em;
			text-align: center;
			white-space: nowrap;
		}

		.button-title {
			font-weight: 600;
		}
	}

	.mat-icon {
		--icon-size: 1.2rem;
		width: var(--icon-size);
		height: var(--icon-size);
		font-size: var(--icon-size);
		line-height: var(--icon-size);
		margin: var(--space-xs) 0;
	}
}

.mat-mdc-icon-button .mat-icon svg {
	width: 100%;
	height: 100%;
}

.mat-mdc-unelevated-button.mat-accent > .mat-icon {
	font-size: 24px;
	width: auto;
	height: auto;
	margin: 0;
}

.mdc-button.mat-accent:not(.mat-mdc-outlined-button),
.mdc-button.mat-warn.mat-mdc-unelevated-button {
	& > .mdc-button__label,
	& > .mat-icon {
		color: var(--accent-contrast-color);
	}
}

.custom-icon-button.mat-mdc-unelevated-button:disabled {
	background-color: transparent;
	opacity: 0.5;
}

.mat-icon.bullet {
	&.report {
		color: #0091ff;
	}
	&.first-email {
		color: #46be90;
	}
	&.last-email {
		color: #a21111;
	}
	&.last-publication {
		color: #ecc801;
	}
}

.mat-mdc-button-base.auto-height {
	height: auto;
	min-height: 36px;
}

.mdc-icon-button.mat-mdc-icon-button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}
