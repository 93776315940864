table.mat-mdc-table {
	box-shadow: none;
	border: 1px solid var(--border-color);
	border-right: none;
}

.progress-bar-container {
	position: absolute;
	display: block;
	width: 100%;
	// Move progress bar above table.
	z-index: 1000;
	top: 0;
}

td.mat-mdc-cell,
th.mat-mdc-header-cell {
	position: relative;
	text-align: center;
	text-overflow: ellipsis;
	border-right: 1px solid var(--border-color);
	padding: 12px;
}

.mat-mdc-row:last-child .mat-mdc-cell {
	border-bottom: none;
}

.mat-mdc-header-cell {
	font-weight: 700;
	color: var(--base-color);
	white-space: nowrap;
	height: 60px;

	div {
		justify-content: center;
	}

	&.cdk-drag {
		.mat-focus-indicator {
			position: static;
		}
	}
}

td.mat-mdc-cell.empty-table-message {
	justify-content: center;
	text-align: center;
	border-bottom-width: 0;
}

td.mat-mdc-cell:last-of-type {
	padding-right: 0;
}

td.mat-mdc-cell.highlight-cell,
tr.mat-mdc-row.highlight-row {
	background-color: var(--highlight-table-cell);
}

tr.row-border-top td {
	border-top: 1px solid black;
}

scriptaw-advanced-base-table {
	.mat-sort-header-content {
		height: 100px;
	}

	.mat-mdc-cell {
		span {
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.mat-mdc-cell,
	.mat-mdc-header-cell {
		border-right: 1px solid rgba(0, 0, 0, 0.12);
	}
}

.table-diff_delete {
	color: var(--warn-color);
	text-decoration: line-through;
}

.table-diff_add {
	font-weight: 700;
	color: var(--accent-color);
}

.mat-mdc-header-row > .mat-mdc-header-cell {
	font-weight: 700;
}

.mat-mdc-table-sticky {
	background-color: var(--base-background-color);
}
